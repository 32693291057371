.layout-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  &__header {
    height: 72px;
    width: 100%;
  }
  &__body {
    flex-grow: 1;
    width: 100%;
  }
  &__footer {
    height: 72px;
    width: 100%;
  }
}
