.home {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Poppins;
  margin: 10px;
  color: #3d3d3d;
  --mdc-theme-primary: #007f8b;
  --mdc-theme-on-primary: #f1f3f4;
}

h1 {
  font-style: italic;
  color: #ff6f00;
  color: #007f8b;
}

h2 {
  clear: both;
}

em {
  font-weight: bold;
}

video {
  clear: both;
  display: block;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

section {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

header,
footer {
  clear: both;
}

.removed {
  display: none;
}

.invisible {
  opacity: 0.2;
}

.note {
  font-style: italic;
  font-size: 130%;
}

.videoView,
.detectOnClick {
  position: relative;
  float: left;
  width: 48%;
  margin: 2% 1%;
  cursor: pointer;
  /* height: initial; */
}

.highlighter {
  background: rgba(0, 255, 0, 0.25);
  border: 1px dashed #fff;
  z-index: 1;
  position: absolute;
}

.canvas {
  z-index: 1;
  position: absolute;
  pointer-events: none;
}

._output_canvas {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.output_canvas {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.detectOnClick {
  z-index: 0;
}

.detectOnClick img {
  width: 100%;
}

.blend-shapes-item {
  display: flex;
  align-items: center;
  height: 20px;
}

/* .blend-shapes {
    position: relative;
    float: inherit;
    width: inherit;
    height: inherit;
    margin: 0;
    cursor: pointer;
} */

.blend-shapes-label {
  display: flex;
  width: 180px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 4px;
}

.face-shapes-label {
  display: flex;
  width: 180px;
  justify-content: flex-start;
  align-items: center;
  margin-right: 4px;
}
.blend-shapes-list {
  overflow: scroll;
  max-height: 40vh;
  min-height: 40vh;
  border: 1px solid black;
  padding: 20px 20px;
}

.blend-shapes-value {
  display: flex;
  height: 16px;
  align-items: center;
  transition: all 0.3s ease-in-out;
  background-color: #007f8b;
}

.facial-wrapper-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.succuss-gif {
  width: 120px;
  height: 120px;
}
.succuss-gif-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 90vh;
}
.succuss-message-label {
  font-size: 24px;
  font-weight: 600;
}
.waiting-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80vh;
}
.waiting-loader {
  width: 100px;
  height: 100px;
}
.success-class {
  display: none;
}

#palm {
  filter: invert(1);
  width: 300px;
  height: 340px;
  padding-bottom: 20px;
  position: absolute;
  /* object-fit: cover; */
  transform: scaleX(-1);
  left: 26%;
  top: 62%;
  margin-left: 14px;
}

.terms_header_container {
  height: 60%;
}

.qr-container {
  display: flex;
  justify-content: center;
  height: 70%;
  width: 100%;
}
.oboardingflow-dropdown {
  margin-bottom: 10px !important;
  width: 98% !important;
}

.BusinessSession--container--web-component {
  height: 80vh;
  overflow: scroll;
}
