.event-profile {
	&__header {
		display: flex;
		padding-left: 24px;
		border-bottom: 1px solid var(--color-gray-dark-100);
	}
	&__header_text {
		font-weight: 600;
		font-size: 13px;
		color: var(--color-text-50-light);
		padding: 8px;
		border-radius: 2px;
		cursor: pointer;
		margin-bottom: -1px;
		&__active {
			color: #0051cc !important;
			border-bottom: 2px solid #0051cc;
		}
	}
}
