.event-profile-tag {
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  width: "fit-content";
}

.spoof {
  border: 1px solid red !important;
}

.real {
  border: 1px solid green !important;
}

.profile-meta {
  background: rgb(244, 245, 245);
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 80px 85px 1fr 1fr;
  padding: 4px;
  column-gap: 8px;
  &__sublabel {
    font-weight: 600;
    font-size: 14px;
  }
  &__inner {
    display: flex;
    flex-direction: column;
  }
}

.profile-wrapper {
  transition: all 0.3s ease-in-out;
}

.profile-wrapper:hover {
  cursor: pointer;
  box-shadow: 10px 10px 8px 0px rgba(0, 0, 0, 0.15);
}
.break {
  word-break: break-all;
}

.image-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}

.event-meta-icon {
  padding: var(--zero-padding) calc(var(--base-padding) * 0.25);
  cursor: pointer;
  font-weight: 600;
  opacity: 0.5;
  font-size: 24px;
}
