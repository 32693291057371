.event-title {
  font-size: 16px;
  font-weight: 600;
}

.event-title--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-title--wrapper span {
  font-size: 12px;
}

.unset_rotate {
  transform: unset !important;
  -webkit-transform: unset !important;
  -moz-transform: unset !important;
  ._output_canvas {
    transform: unset !important;
    -webkit-transform: unset !important;
    -moz-transform: unset !important;
  }
  #_webcam {
    transform: unset !important;
    -webkit-transform: unset !important;
    -moz-transform: unset !important;
  }

  .code-outline-highlight {
    -moz-transform: unset !important;
    -webkit-transform: unset !important;
    -o-transform: unset !important;
    -ms-transform: unset !important;
    transform: unset !important;
  }
}
