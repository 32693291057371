@import "variables/colors.scss";
@import "variables/font-size.scss";
@import "variables/padding.scss";
@import "variables/border-radius.scss";
@import "variables/margin.scss";

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Poppins";
}


.code-outline-highlight{
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}


.success-qr{
  .code-outline-highlight{
    // fill:rgba(57, 255, 20, 0.8) !important;
    stroke:rgba(57, 255, 20, 0.8) !important
  }
}

.invalid-qr{
  .code-outline-highlight{
    // fill:rgba(255, 0, 0, 0.8) !important;
    stroke:rgba(255, 0, 0, 0.8) !important
  }
}