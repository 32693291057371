.event-wrapper {
  position: fixed;
  inset: 0;
  &__matched-profiles {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    height: calc(100vh - 500px);
    padding: 8px;
    overflow: scroll;
    padding-bottom: 100px;
  }
  &__unregistered-profiles {
    height: 100vh;
    overflow: scroll;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__center-image {
    height: 384px;
    object-fit: cover;
    width: 100%;
  }
  &__btn-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid #0000001f;
  }
}

// .event-wrapper__center {
//   width: 50%;
// }

textarea:focus,
input:focus {
  outline: none;
}

.event-wrapper__header {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  height: 60px;
  padding: 12px 16px;
  border-bottom: 1px solid #0000001f;
  &__right {
    width: 368px;
  }

  .profile-img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    border-radius: 50%;
  }
}

.event-wrapper__left {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-right: 1px solid #0000001f;
}
.event-wrapper__right {
  gap: 4px;
  width: 100%;
  border-left: 1px solid #0000001f;
}

.approve-btn {
  color: var(--color-primary-light);
  border: 1px solid #389f75;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  height: 36px;
  text-align: center;
  cursor: pointer;
  width: calc(50% - 4px);
  &:hover {
    opacity: 0.6;
    transition: 0.4s ease-in-out;
  }
}
.deny-btn {
  color: #ef4343;
  border: 1px solid #ef4343;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  height: 36px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  width: calc(50% - 4px);
  &:hover {
    opacity: 0.6;
    transition: 0.4s ease-in-out;
  }
}

.relative {
  position: relative;
}
.event-wrapper__body {
  display: grid;
  grid-template-columns: 384px 1fr 300px;
  .profile-body-img {
    height: 108px;
    width: 80px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #0000001f;
  }
}

.d-flex {
  display: flex;
}
.direction-column {
  flex-direction: column;
}
.align-item-center {
  align-items: center;
}

.gp-8 {
  gap: 8px;
}

.gp-16 {
  gap: 16px;
}
.p-8 {
  padding: 8px;
}

.profile-padding {
  padding: 12px 16px;
}

.br-bottom {
  border-bottom: 1px solid #0000001f;
}

.br-right {
  border-right: 1px solid #0000001f;
}

.pl-16 {
  padding-left: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-12 {
  padding-left: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pt-8 {
  padding-top: 8px;
}
.pt-16 {
  padding-top: 16px;
}
.p-16 {
  padding: 16px;
}
.w-50 {
  width: calc(50% - 8px);
}

.w-400 {
  width: 400px;
}
.event-matched-profile {
  height: fit-content;
  &:nth-child(odd) {
    border-right: 1px solid #0000001f;
  }
}

.w-100 {
  width: 100%;
}

.approved-modal {
  width: 480px !important;
  .Modal__wrapper {
    width: 480px;

    padding: 0px !important;
  }
  &__header {
    border-bottom: 1px solid #dfe3ec;
    margin-bottom: 16px;
    padding: 24px 24px 8px 24px;
  }
  &__label {
    font-size: 16px;
    font-weight: 600;
  }

  &__sublabel {
    font-size: 14px;
    color: #6c7489;
  }
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.center-align {
  padding: 0px 16px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: calc(50% - 100px);
}
@media screen and (max-width: 1220px) {
  .sm-100 {
    width: 100% !important;
  }
}

.content-center {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.mt--100 {
  margin-top: -100px;
}

.event-empty-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--base-margin);
  &__message {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #464c5c;
  }
  &__sub-message {
    text-align: center;
    font-size: 14px;
    color: #747e99;
    margin-bottom: 24px;
  }
}
