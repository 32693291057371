.pending-profile-wrapper {
	width: 100%;
	// height: 100%;
	border: 1px solid #0000001f;
	padding: 8px;
	border-radius: 4px;
	&__img {
		width: 100%;
		// height: 300px;
		object-fit: contain;
	}
	&__img__wrapper{
		position:relative;
		width: 100%;
		// height: 300px;
	}
	&__loader{
		 position: absolute;
		 top:50%;
		 left: 50%;
		 transform: translate(-50% , -50%);
	}
	.opacityhalf{
		opacity: 0.5;
	}
}


.opacityhalf{
 opacity: 0.5;
}