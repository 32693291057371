.circle-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loader {
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.gif-loader-custom{
  width: 180px;
  height: 180px;
  object-fit: contain;
}
.loader-blue {
  border: 3px solid #0051cc;
  border-top: 3px solid #ffffff;
}
.loader-white {
  border: 3px solid #ffffff;
  border-top: 3px solid #0051cc;
}
.loader-pink {
  border: 3px solid #ffffff;
  border-top: 3px solid #f56262;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
