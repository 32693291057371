.qr-header {
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
}

.qr-wrapper {
  border-top: 1px solid #0000001f;
  border-bottom: 1px solid #0000001f;
  height: 100%;
  padding: 16px;
}

.qr-container {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.qr-header-content {
  height: 50px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &__time {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.qr-content {
  flex-grow: 1;
  display: grid;
  place-items: center;
  height: 100%;
}

.qr-center {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.qr-meta-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.qr-footer {
  height: 200px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.qr-location {
  display: flex;
  gap: 8px;
}

.qr-meta {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.qr-meta__inner {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.qr-footer-button {
  width: 100%;
  display: grid;
  height: 100%;
  place-items: center;
  padding: 16px;
}

.icon {
  font-size: 24px;
  color: #7c8398;
}
